import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../containers/layout';
import HeaderPage from '../components/HeaderPage';
import Paragraph from '../components/Paragraph';
import VisualComposer from '../components/VisualComposer';
import Contacts from '../components/Contacts';
import Accordion from '../components/Accordion';
import AccordionList from '../components/AccordionList';
import Container from '../components/Container';
import FeedWithPagination from '../components/FeedWithPagination';

const SingleRegionComitati = ({ data, pageContext }) => {
  const {
    title,
    sede,
    mappa,
    orari,
    contatti,
    facebook,
    instagram,
    twitter,
    presidente,
    segretario,
    vicepresidente,
    subtitle,
    content,
    seoMetaTags
  } = data.datoCmsRegioni;
  const localNews = data.allDatoCmsLocalNewsRegion.nodes;

  const { slugArchive } = { slugArchive: data.datoCmsComitatiLocaliArchive.slug };
  const contactsRows = [];
  const contactFirstRow = {
    title: 'Informazioni',
    hasBorder: true,
    contacts: []
  };
  const contactSecondRow = {
    title: 'Descrizione',
    contacts: []
  };

  const sedeBlock = {
    title: 'Sede',
    richText: sede,
    linkMap: mappa
  };

  const orariBlock = {
    title: 'Orari',
    richText: orari,
  };

  const contattiBlock = {
    title: 'Contatti',
    richText: contatti,
    linkFacebook: facebook,
    linkTwitter: instagram,
    linkInstagram: twitter
  };

  const presidenteBlock = {
    title: 'Presidente',
    richText: presidente,
  };

  const segretarioBlock = {
    title: 'Segretario',
    richText: segretario
  };

  const vicepresidenteBlock = {
    title: 'Vicepresidente',
    richText: vicepresidente,
  };

  contactFirstRow.contacts.push(sedeBlock);
  contactFirstRow.contacts.push(orariBlock);
  contactFirstRow.contacts.push(contattiBlock);

  contactSecondRow.contacts.push(presidenteBlock);
  contactSecondRow.contacts.push(segretarioBlock);
  contactSecondRow.contacts.push(vicepresidenteBlock);

  contactsRows.push(contactFirstRow);
  contactsRows.push(contactSecondRow);

  const {
    province
  } = { province: data.allDatoCmsProvince.nodes };

  return (
    <Layout
      seoMetaTags={seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      pageType="Local Commitee Group"
      canonical={pageContext.canonical}
    >
      <HeaderPage
        title={title}
        model={data.datoCmsComitatiLocaliArchive.title}
        labelParent={data.datoCmsComitatiLocaliArchive}
        parentPathname={pageContext.parentPathname}
      />
      {
        (sede || contatti || presidente || segretario || vicepresidente) && (
          <Contacts
            contactsRows={contactsRows}
          />
        )
      }
      {
        content && (
          <div className="page-wrapper comitali-wrapper">
            <Paragraph
              subtitle={subtitle}
              content={content}
              marginTop="grande"
              marginBottom="piccolo"
              numCols="zero"
            />
          </div>
        )
      }
      {
        province.length > 0 && (
          <Container
            className="comitati__accordions"
            marginTop="grande"
            marginBottom="grande"
          >
            <div className="comitati__accordions-wrapper">
              <div className="comitati__accordion">
                <Accordion isActive title="Comitati Locali">
                  <AccordionList
                    list={province}
                  />
                </Accordion>
              </div>
            </div>
          </Container>
        )
      }
      {
        data && data.datoCmsRegioni && (
          <VisualComposer
            components={data.datoCmsRegioni.visualComposer}
          />
        )
      }
      {
        localNews.length > 0 && (
          <FeedWithPagination
            slugArchive={slugArchive}
            allNews={localNews}
          />
        )
      }
    </Layout>
  );
};

export default SingleRegionComitati;

export const SINGLEREGIONI_QUERY = graphql`
  query SingleRegioniQuery($id: String!) {
    datoCmsRegioni(id: {eq: $id}) {
      id
      title
      slug
      sede
      mappa
      orari
      contatti
      facebook
      instagram
      twitter
      presidente
      segretario
      vicepresidente
      subtitle
      content
      seoMetaTags {
        tags
      }
      visualComposer {
        ...BannerText
        ...CenteredNumbers
        ...Numbers
      }
    }
    datoCmsComitatiLocaliArchive {
      slug
      title
    }
    allDatoCmsLocalNewsRegion(
      sort: {fields: publicationDate, order: DESC},
      filter: {comitato: {id: {eq: $id}}}
    ){
      nodes {
        id
        title
        slug
        publicationDate
        comitato {
          id
          title
          slug
        }
      }
    }
    allDatoCmsProvince(filter:  { regione: {id: {eq: $id}} }) {
      nodes {
        title
        slug
        regione {
          id
        }
      }
    }
  }
`;
